import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
// import Register from '../views/test/Register.vue'


Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes:[
    {
      path: '/',
      redirect: '/Login'
    },
    {
      path: '/Login',
      name: 'Login',
      component: Login,
    },
    // {
    //   path: '/Register',
    //   name: 'Register',
    //   component: Register,
    // },
    {
      path: '/Frame',
      name: 'Frame',
      component: ()=> import('../components/Frame.vue'),
      redirect: '/Tongue',

      children:[
        {
          path: '/Palm',
          name: 'Palm',
          component: () => import('../components/pages/Palm.vue')
        },
        {
          path: '/Tongue',
          name: 'Tongue',
          component: () => import('../components/pages/Tongue.vue')
        },
        {
          path: '/Eye',
          name: 'Eye',
          component: () => import('../components/pages/Eye.vue')
        },
        // {
        //   path: '/Stream',
        //   name: 'Stream',
        //   component: () => import('../components/pages/test/Stream.vue')
        // },

        // {
        //     path: '/record',
        //     name: 'Record',
        //     component: () => import('../components/pages/test/Record.vue')
        // },
        {
          path: '/result',
          name: 'Result',
          component: () => import('../components/pages/result.vue')
      },
      //   {
      //     path: '/report',
      //     name: 'Report',
      //     component: () => import('../components/pages/report.vue')
      // },
      {
        path: '/HRSPO2',
        name: 'HRSPO2',
        component: () => import('../components/pages/HRSPO2.vue')
      },
      
      ]
    }
    ]
})


export default router


// 解决vue-router在3.0版本以上重复报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}